import React, { useEffect } from 'react';
import {
  LookingForSomethingElse,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import MoreOptionsOrder from 'src/components/MoreOptionsOrder';
import { IconGhost, NoDataText, Section } from 'src/styles/styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  OrdersAndPaymentScreenDisplayed,
  publishEvent,
  ScreenName,
  ScreenPath
} from 'src/utils/analytics';
import { Icon, NoOrders, OrderContainer } from './styles';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlagsEnum } from 'src/utils/enums';

export interface OrderProps {
  status: 'Completed' | 'Shipped' | 'Processing';
  orderNumber: string;
  orderedDate: string;
  total: string;
  subscriptionType?: SubscriptionType;
}
export interface OrdersProps {
  orders: OrderProps[];
}

const Orders = ({ orders }: OrdersProps) => {
  const { translate } = useDependencyManagerContext();

  const instantInkTotal = orders?.filter(
    (order) => order.subscriptionType === SubscriptionType.INSTANT_INK
  )?.length;
  //TODO: Remember to implement total count to totalHpAllInCt
  const OrdersTotal = `totalOrdersCt=${instantInkTotal}&totalInstanInkCt=${instantInkTotal}&totalHpAllInCt=0`;

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagsEnum.originalFeedbackExperience);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isOriginalFeedbackExperienceLoading;

  //TODO: Remember to {loading && <Loading />} and {error && <ErrorBoundary analyticsEvent={OrdersError(error.message)} />}
  useEffect(() => {
    publishEvent(OrdersAndPaymentScreenDisplayed(OrdersTotal));
  }, [OrdersTotal]);

  return (
    <OrderContainer data-testid="orders-page">
      <Section
        appearance="outlined"
        content={
          <NoOrders>
            <Icon>
              <IconGhost size={40} />
            </Icon>
            <NoDataText>
              {`${translate('orders.noOrdersYet', 'No orders yet!')}`}
            </NoDataText>
          </NoOrders>
        }
      />
      <MoreOptionsOrder />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: ScreenName.ORDERS,
            screenPath: ScreenPath.ORDERS
          }}
          translate={translate}
        />
      )}
    </OrderContainer>
  );
};

export default Orders;
