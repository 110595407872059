export enum OrderStatus {
  COMPLETED = 'Completed',
  SHIPPED = 'Shipped',
  PROCESSING = 'Processing',
  UNKNOWN_STATUS = 'Unknown status'
}

export enum FeatureFlagsEnum {
  qualtricsSurvey = 'qualtrics-survey',
  originalFeedbackExperience = 'original-feedback-experience'
}

export enum PaymentMethodType {
  CREDIT_CARD,
  PAY_PAL,
  TWO_CHECKOUT,
  DIRECT_DEBIT,
  CITIZENS_PAY
}

export enum PaymentMethodStatus {
  ACTIVE,
  INACTIVE,
  DISABLED,
  DELETED
}
